import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import VerticalMenu from "./LeftMenu/VerticalMenu";

const Main = () => {
  const [isOpen, setOpen] = useState(false);

  const onToggle = (data: boolean) => {
    setOpen(data);
  };

  return (
    <>
      <Header toggle={onToggle} />
      <div id="main-app" className="layout-veritcle-menu">
        <VerticalMenu isToggle={isOpen} />
        <div className="app-body">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Main;
