import Loadericon from '../../../assets/images/Loader.gif'

const PageLoader = () => {
    return (
        <>
            <div className="page-loader">
                <div className="loader-icon">
                    <img src={Loadericon} width={150} alt="" />
                </div>
            </div>
        </>
    )
}
export default PageLoader;