import {
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  InputGroup,
  Form,
  Button,
  Badge,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { PiCaretRightBold } from "react-icons/pi";
import { BsFilter, BsThreeDotsVertical } from "react-icons/bs";
import { HiMiniChevronUpDown } from "react-icons/hi2";
import FilterServiceRequest from "./FilterServiceRequest";
import PageTitle from "../../components/PageTitle";
import NoDataFound from "../Common/NoDataFound/NoDataFound";
import "./maintenance.scss";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import HelperService from "../../Services/HelperService";
import DeleteModal from "../Common/DeleteModal/DeleteModal";
import CompoLoader from "../Common/ComponentLoader/CompoLoader";
import RentCityPagination from "../Common/Pagination/RentCityPagination";
import { Access } from "../../assets/interfaces/interface";
import { MdClose } from "react-icons/md";

const Maintenance = () => {
  let navigate = useNavigate();
  const [pageLoader, setPageLoader] = useState(false);
  const [serviceList, setServiceList] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [keyword, setKeyWord] = useState("");
  const [sortBy, setsortBy] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [maintenanceCount, setMaintenanceCount] = useState({
    count: 0,
    today_request_count: 0,
    total_request_open: 0,
    total_request_overdue: 0,
  });
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [action, setAction] = useState("");
  const [filterObject, setFilterObject] = useState(null);
  const [showfilter, setShowFilter] = useState(false);
  const [load, setLoad] = useState(Math.random());
  const [access, setAccess] = useState<Access | null>(null);
  const [handleCancelSearch, setHandleCancelSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    getServiceRequestList();
  }, [page, sortBy, orderBy, filterObject,keyword]);
  

  const getServiceRequestList = () => {
    setPageLoader(true);
    var qurey = "";
    if (filterObject !== null)
      var qurey = new URLSearchParams(filterObject).toString();

    return WebService.getAPI({
      action: `maintenances?keyword=${keyword}&offset=${page}&sort_by=${sortBy}&order_by=${orderBy}&${qurey}`,
    })
      .then((res: any) => {
        setServiceList(res?.list);
        setMaintenanceCount((prev: any) => ({
          ...prev,
          count: res?.count,
          today_request_count: res?.today_request_count,
          total_request_open: res?.total_request_open,
          total_request_overdue: res?.total_request_overdue,
        }));
        setAccess(res?.access);
        setPageLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error(error?.response?.data?.message);
        setPageLoader(false);
      });
  };
  const handleCloseModal = () => {
    setshowDeleteModal(false);
  };
  const getPropertyBreadCrumb = (
    building: any,
    unit: any,
    room: any,
    bed: any,
    propertyType: any
  ) => {
    let building1 = "";
    let unit1 = "";
    let room1 = "";
    let bed1 = "";
    console.log(
      "building",
      building,
      "unit",
      unit,
      "room",
      room,
      "bed",
      bed,
      propertyType
    );

    if (propertyType === "MULTIPLE_UNIT" && building !== null) {
      building1 = building?.building_name;
    }
    if (propertyType === "MULTIPLE_UNIT" && unit !== null) {
      unit1 = `-${unit?.room_no}`;
    }
    if (room !== null) {
      if (propertyType === "MULTIPLE_UNIT") {
        room1 = `-${room?.master_value?.title}`;
      } else {
        room1 = `${room?.master_value?.title}`;
      }
    }
    if (bed !== null) {
      bed1 = `-${bed?.value}`;
    }
    return `${building1}${unit1}${room1}${bed1}`;
  };
  const handleSearch = (e: any) => {
    if (e.which === 13) {
      getServiceRequestList();
    }
  };
  const handleEdit = (reqId: any) => {
    navigate("/add-service-request", {
      state: {
        serviceReqUUID: reqId,
      },
    });
  };
  const handleDelete = (reqId: any) => {
    setAction(`property/${reqId}/maintenance`);
    setshowDeleteModal(true);
  };
  return (
    <>
      <div className="app-page pt-2 pt-lg-3 page-maintenance">
        <div className="mb-4 pb-2">
          <PageTitle title="Maintenance" backArrow={false} />
        </div>
        <h4 className="font-semibold font-16">Overview </h4>
        <Row className="mb-4 gx-3 gx-lg-4">
          <Col xs={4}>
            <Card className="card-style card-padding">
              <Card.Body>
                <h1 className="font-22 font-bold mb-3 text-center">
                  {maintenanceCount?.today_request_count}
                </h1>
                <p className="mb-0 text-secondary text-center">
                  Requests Received Today
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card className="card-style card-padding">
              <Card.Body>
                <h1 className="font-22 font-bold mb-3 text-center">
                  {maintenanceCount?.total_request_open}
                </h1>
                <p className="mb-0 text-secondary text-center">
                  Total Requests Open
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card className="card-style card-padding h-100">
              <Card.Body>
                <h1 className="font-22 font-bold mb-3 text-center">
                  {maintenanceCount?.total_request_overdue}
                </h1>
                <p className="mb-0 text-secondary text-center">
                  Requests Overdue
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {pageLoader ? <CompoLoader defaultHeight={800} /> : ""}
        <Row className="">
          <Col lg={4} className="d-flex gap-2 align-items-sm-center">
            <h4 className="font-16 font-semibold mb-lg-2 mb-3 text-center">
              Service Request{" "}
              <span className="font-12 font-medium text-secondary-dark text-center">
                ({maintenanceCount?.count} Request)
              </span>
            </h4>
          </Col>
          <Col lg={8} className="d-flex align-items-center gap-3 ">
          <InputGroup className="search-box mb-lg-2 mb-2">
                <InputGroup.Text id="basic-addon1">
                  <FiSearch className="icon" />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search for requests...."
                  value={searchText}
                  onChange={(e) => {
                    setHandleCancelSearch(true);
                    setSearchText(e.currentTarget.value);
                    if(e.currentTarget.value === ""){
                      setKeyWord("");
                      setHandleCancelSearch(false);
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === "Enter") {
                      setKeyWord(e.currentTarget.value);
                    }
                  }}
                />
                <InputGroup.Text id="basic-addon1">
                  {handleCancelSearch && (
                    <MdClose 
                      onClick={() => {
                        setKeyWord("");
                        setSearchText("");
                        setHandleCancelSearch(false);
                      }}
                      className="icon"
                    />
                  )}
                </InputGroup.Text>
              </InputGroup>
            <Link
              to="/service-request-filter"
              className="btn btn-outline-light d-lg-none d-inline mb-2"
            >
              {" "}
              <BsFilter size={16} />
            </Link>
            <Dropdown
              align="end"
              autoClose="outside"
              show={showfilter}
              className="filter-dropdown d-none d-lg-inline mb-lg-2 mb-3"
            >
              <Dropdown.Toggle
                className="btn btn-outline-light"
                id="dropdown-basic"
                onClick={() => {
                  setShowFilter(!showfilter);
                  setLoad(Math.random());
                }}
              >
                <BsFilter size={16} /> Filter
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <FilterServiceRequest
                  showFilter={showfilter}
                  setFilter={(obj: any) => {
                    setFilterObject(obj);
                    setShowFilter(false);
                  }}
                  load={load}
                />
              </Dropdown.Menu>
            </Dropdown>
            {/* <Button className="btn-outline-light mb-2">
              <LuDownload size={13} className="text-secondary" />
            </Button> */}
            {access?.can_create === "1" && (
              <Button
                onClick={() => {
                  navigate("/add-service-request", {
                    state: { property_id: "" },
                  });
                }}
                className="btn btn-outline-brand mb-2 text-nowrap d-none d-lg-inline"
              >
                Add Request
              </Button>
            )}
          </Col>
        </Row>
        <div className="table-style-1 table-maintenance">
          <table className="table">
            <thead className="">
              <tr>
                <th className="d-flex">
                  Request ID{" "}
                  <span className="sorting-icon">
                    <HiMiniChevronUpDown />
                  </span>
                </th>
                <th>
                  Date{" "}
                  <span className="sorting-icon">
                    <HiMiniChevronUpDown />
                  </span>
                </th>
                <th>
                  Raised By{" "}
                  <span className="sorting-icon">
                    <HiMiniChevronUpDown />
                  </span>
                </th>
                <th>
                  Property Details{" "}
                  <span className="sorting-icon">
                    <HiMiniChevronUpDown />
                  </span>
                </th>
                <th>
                  Category{" "}
                  <span className="sorting-icon">
                    <HiMiniChevronUpDown />
                  </span>
                </th>
                <th>
                  Urgency{" "}
                  <span className="sorting-icon">
                    <HiMiniChevronUpDown />
                  </span>
                </th>
                <th>
                  Status{" "}
                  <span className="sorting-icon">
                    <HiMiniChevronUpDown />
                  </span>
                </th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {serviceList && serviceList.length > 0 ? (
                serviceList.map((request: any, index: any) => {
                  return (
                    <tr key={index} className="">
                      <td className="col-req-id">
                        <span className="font-semibold">
                          {request?.request_no}
                        </span>
                      </td>
                      <td className="col-date urgency-high text-truncate">
                        <label className="font-12 text-secondary-dark font-regular mb-1 d-lg-none d-block">
                          Date
                        </label>{" "}
                        {HelperService?.getFormatedDateForDetail(
                          request?.created_at,
                          "DD-MMM-YYYY"
                        )}
                      </td>
                      <td className="col-raised-by text-truncate">
                        <label className="font-12 text-secondary-dark font-regular mb-1 d-lg-none d-block">
                          Raised by
                        </label>
                        {request?.tenant?.name}
                      </td>
                      <td className="col-unit">
                        <div> {request?.property?.name}</div>
                        <div>
                          {getPropertyBreadCrumb(
                            request?.building,
                            request?.floor_unit,
                            request?.room,
                            request?.bed,
                            request?.property?.property_type
                          )}
                        </div>
                        {/* <div className="text-secondary-dark">A-101</div> */}
                      </td>
                      <td className="col-category text-truncate">
                        <label className="font-12 text-secondary-dark font-regular mb-1 d-lg-none d-block">
                          Category
                        </label>
                        {request?.category?.title}
                      </td>
                      <td className="col-urgency">
                        {" "}
                        {request?.priority ? (
                          <Badge
                            className={
                              request?.priority === "HIGH"
                                ? "badge-danger"
                                : request?.priority === "MEDIUM"
                                ? "badge-warning"
                                : request?.priority === "LOW"
                                ? "badge-light"
                                : ""
                            }
                          >
                            {HelperService.removeUnderScore(
                              request?.priority ?? "-"
                            )}{" "}
                          </Badge>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="col-status">
                        {" "}
                        <Badge
                          className={
                            request?.status === "OPEN"
                              ? "badge-green"
                              : request?.status === "CLOSED"
                              ? "badge-danger"
                              : ""
                          }
                        >
                          {HelperService.titleCase(request?.status)}
                        </Badge>
                      </td>
                      <td className="col-action">
                        <div className="action-btns">
                          {access?.can_read === "1" && (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip id=""> View Details </Tooltip>}
                            >
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/maintenance-details/${request?.uuid}`
                                  );
                                }}
                                className="btn btn-outline-light px-2"
                              >
                                <PiCaretRightBold size={14} />
                              </Button>
                            </OverlayTrigger>
                          )}

                          <Dropdown
                            align="end"
                            autoClose="outside"
                            className="action-dropdown no-down-arrow"
                          >
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip id=""> More </Tooltip>}
                            >
                              <Dropdown.Toggle
                                className="btn btn-outline-light"
                                id="dropdown-basic"
                              >
                                <BsThreeDotsVertical
                                  size={16}
                                  className="text-secondary"
                                />
                              </Dropdown.Toggle>
                            </OverlayTrigger>
                            <Dropdown.Menu>
                              {access?.can_update === "1" && (
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() => handleEdit(request?.uuid)}
                                  className="dropdown-item"
                                >
                                  Edit
                                </Link>
                              )}

                              {access?.can_delete === "1" && (
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() => handleDelete(request?.uuid)}
                                  className="dropdown-item"
                                >
                                  Delete
                                </Link>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8}>
                    <NoDataFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {maintenanceCount?.count > 10 ? (
            <div className="float-end">
              <RentCityPagination
                totalCount={maintenanceCount?.count}
                changePage={(page: any) => {
                  setPage(page - 1);
                }}
                itemCountPerPage={10}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <DeleteModal
        show={showDeleteModal}
        closeModal={(flag: any) => {
          if (flag) {
            getServiceRequestList();
          }
          handleCloseModal();
        }}
        action={action}
      />
    </>
  );
};
export default Maintenance;
