import { Card, Row, Col, Button } from "react-bootstrap";
import Loadericon from "../../../assets/images/Loader.gif";
import { useState } from "react";
interface PropData {
  defaultHeight?: any;
}
const CompoLoader = (props: PropData) => {
  const [opacity, setOpacity] = useState(0);

  setTimeout(() => {
    setOpacity(1);
  }, 5000);
  return (
    <>
      <div
        className="component-loader"
        style={{ minHeight: props?.defaultHeight}}
      >
        <div className="loader-icon">
          <img src={Loadericon} width={60} alt="" />
        </div>
      </div>
    </>
  );
};
export default CompoLoader;
