import { combineReducers } from '@reduxjs/toolkit';
import { ADD_PROFILE_DETAILS } from './action';

const initialState = {
profile_setting:{}
};

export const profileReducer = (state = initialState, action:any) => {
 
  switch (action.type) {
    case ADD_PROFILE_DETAILS:
      return {
        ...state,
        profile_setting: action.payload,
      };
   
    default:
      return state;
  }
};
