import { useEffect, useState } from "react";
import {
  Container,
  Navbar,
  Dropdown,
  InputGroup,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import Logo from "../../assets/images/logo.svg";
import avtar from "../../assets/images/avtar-male.png";
import Notificationicon from "../../assets/images/icon-notification-bell.svg";
import { FiSearch } from "react-icons/fi";
import { HiOutlineMenu } from "react-icons/hi";
import { Link } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";

import NotificationItems from "./NotificationItems";
import calendarIconWhite from "../../assets/images/icon-calendar-plus-white.svg";
import menPlaceHolder from "../../assets/images/default-avatar.jpg";
import { useSelector } from "react-redux";

interface PropData {
  toggle: any;
}

const Header = (props: PropData) => {
  const [isActive, setIsActive] = useState(false);
  const [getUserType, setUserType] = useState<any>("")
  const toggleMobMenu = () => {
    props.toggle(!isActive);
    setIsActive(!isActive);
  };
  const [profile_image, setProfileImage] = useState<any>(menPlaceHolder);
  const profileDetails = useSelector(
    (state: any) => state?.profile?.profile_setting
  );
  useEffect(() => {
    if (profileDetails !== null || profileDetails !== undefined) {
      setProfileImage(profileDetails?.profile_image);
    }
    setUserType(localStorage.getItem("user_mode"))
  }, [profileDetails]);

  return (
    <>
      <header className="site-header">
        <Container fluid className="header-nav">
          <Navbar className=" justify-content-between">
            <Navbar.Brand className=" ">
              <img
                src={Logo}
                width={70}
                height={18}
                alt="Logo"
                className="d-none d-lg-inline"
              />
              <button
                className="btn p-0 text-white d-lg-none d-inline"
                onClick={toggleMobMenu}
              >
                <HiOutlineMenu size={20} />
              </button>
            </Navbar.Brand>
            {/* {getUserType }
            {(getUserType !== "SUPER_ADMIN" || getUserType !== "SUPER_ADMIN_EMPLOYEE") ? (<InputGroup className="search-box d-none d-lg-flex">
              <InputGroup.Text id="basic-addon1">
                <FiSearch className="icon" />
              </InputGroup.Text>
              <Form.Control placeholder="Search for properties...." />
            </InputGroup>) : ""} */}

            <div className="d-flex align-items-center gap-3">
              {/* <Link
                to="/search"
                className="header-link-btn d-inline-flex d-lg-none"
              >
                <FiSearch className="text-white" size={20} />
              </Link> */}

              {/* <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id=""> Calendar </Tooltip>}
              >
                <Link to="" className="header-link-btn">
                  <img src={calendarIconWhite} width="20" alt="" />
                </Link>
              </OverlayTrigger> */}
              <div className="d-flex gap-3 justify-content-end">
                {/* <Link
                  to="/notifications"
                  className="header-link-btn d-inline-flex d-lg-none"
                >
                  <img
                    src={Notificationicon}
                    width={20}
                    height={20}
                    className=""
                    alt=""
                  />
                </Link> */}
                {/* <Dropdown className="notification-dd d-none d-lg-inline">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id=""> Notifications </Tooltip>}
                  >
                    <Dropdown.Toggle>
                      <img
                        src={Notificationicon}
                        width={20}
                        height={20}
                        className=""
                        alt=""
                      />
                    </Dropdown.Toggle>
                  </OverlayTrigger>
                  <Dropdown.Menu align="end">
                    <div className="px-3 pb-2 font-semibold border-bottom mb-3">
                      Notifications
                    </div>
                    <div className="px-2 border-bottom">
                      <NotificationItems
                        isRead={true}
                        description="You have received a maintenance request from A-302, Hillview"
                        moreLink="/"
                        notiTime="1m ago"
                      />
                      <NotificationItems
                        isRead={false}
                        description="You have received a maintenance request from A-302, Hillview"
                        moreLink="/"
                        notiTime="1m ago"
                      />
                      <NotificationItems
                        isRead={true}
                        description="You have received a maintenance request from A-302, Hillview"
                        moreLink="/"
                        notiTime="4days ago"
                      />
                    </div>
                    <div className="px-3 pt-2 text-end">
                      <button className="btn btn-outline-brand">Cancel</button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown> */}
                <Link to="/profile-menu" className="d-lg-none d-inline">
                  <img
                    src={profile_image}
                    width={34}
                    height={34}
                    className="rounded object-fit-cover"
                    alt=""
                  />
                </Link>
                <Dropdown className="profile-dd d-none d-lg-inline" autoClose={true}> 
                  <Dropdown.Toggle>
                    <div className="d-flex gap-2">
                      <div>
                        <img
                          src={profile_image || menPlaceHolder}
                          width={34}
                          height={34}
                          className=" object-fit-cover"
                          alt=""
                        />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" >
                    <ProfileDropdown profileDetails={profileDetails} />
                    
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Navbar>
        </Container>
      </header>
    </>
  );
};
export default Header;
