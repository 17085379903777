export const GET_ORGANIZATION_LIST = 'GET_ORGANIZATION_LIST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const INVOICE_TYPE_REQUEST = 'INVOICE_TYPE_REQUEST';
export const ADD_ACCOUNT_EXPENSE = 'ADD_ACCOUNT_EXPENSE';
export const ADD_PROFILE_DETAILS='ADD_PROFILE_DETAILS'


export const addOrganization = (payload:any) => ({
  type: GET_ORGANIZATION_LIST,
  payload: payload,
});


export const logoutRequest = () => ({
  type:LOGOUT_REQUEST
});

export const invoiceTypeRequest = (payload:any) => ({
  type:INVOICE_TYPE_REQUEST,
  payload: payload,
});

export const accountExpenseRequest = (payload:any) => ({
  type:ADD_ACCOUNT_EXPENSE,
  payload: payload,
});

export const profileSettings = (payload:any) => ({
  type:ADD_PROFILE_DETAILS,
  payload: payload,
});
