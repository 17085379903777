import { combineReducers, applyMiddleware } from "redux";
// import rootReducer from '../config/reducer'
import {organizationReducer} from "../config/reducer";
import storage from 'redux-persist/lib/storage'
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import { configureStore } from "@reduxjs/toolkit";
import { profileReducer } from "./profileReducer";


const reducers = combineReducers({
          items:organizationReducer,
        profile:profileReducer
   });

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })

export default store;

export type RootState = ReturnType<typeof store.getState>