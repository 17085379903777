import { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

interface PropsData {
  totalCount: any;
  itemCountPerPage: any;
  changePage: any;
}
const RentCityPagination = (props: PropsData) => {
  const [totalPage, setTotalPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    props.changePage(currentPage);
  }, [currentPage]);
  useEffect(() => {
    setTotalPage(Math.ceil(props?.totalCount / props?.itemCountPerPage));
    setLastPage(Math.ceil(props?.totalCount / props?.itemCountPerPage));
  }, [props?.totalCount]);

  return (
    // <div style={{overflow:"y", width:"100px"}} >
    <Pagination>
      {totalPage > 0 && currentPage !== 1 ? (
        <>
          <Pagination.First onClick={() => setCurrentPage(1)} />{" "}
          <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />
        </>
      ) : (
        ""
      )}

      {[...Array(totalPage)].map((_, index) => (
        <Pagination.Item
          key={index}
          active={currentPage == index + 1 ? true : false}
          onClick={() => setCurrentPage(index + 1)}
        >
          {index + 1}
        </Pagination.Item>
      ))}
      {totalPage > 0 && currentPage !== lastPage ? (
        <>
          <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />{" "}
          <Pagination.Last onClick={() => setCurrentPage(lastPage)} />
        </>
      ) : (
        ""
      )}
    </Pagination>
    // </div>
  );
};

export default RentCityPagination;
