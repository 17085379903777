import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { PiCaretLeftBold } from "react-icons/pi";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { accountExpenseRequest } from "../config/action";
interface PropsData {
  pageTitle: string;
  backArrow?: boolean;
  closeBtn?: boolean;
  HelpBtn?: boolean;
  closeUrl?: string;
  clickEvent?: any;
}

const InnerHeader = (props: PropsData) => {
  const goBack = () => {
    window.history.back();
  };
  const dispatch = useDispatch();

  return (
    <>
      <div className="inner-header site-header">
        <div className="header-nav d-flex justify-content-between">
          <div className="d-flex gap-2 align-items-center">
            {props?.backArrow == true ? (
              <button className="btn header-nav-btn" onClick={goBack}>
                <PiCaretLeftBold size={16} />
              </button>
            ) : null}
            <h2 className="page-title mb-0">{props.pageTitle}</h2>
          </div>
          <div className="d-flex gap-3 justify-content-end align-items-center">
            {/* {props?.HelpBtn == true ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id=""> Help </Tooltip>}
              >
                <Link to="" className="btn header-nav-btn help-btn">
                  <RxQuestionMarkCircled size={18} />
                </Link>
              </OverlayTrigger>
            ) : null} */}
            {props?.closeBtn == true ? (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id=""> Close </Tooltip>}
              >
                {props.closeUrl==="" ? (
                  <Button
                    onClick={() => {  
                      window.history.back();
                    }}
                    className="btn header-nav-btn"
                  >
                    <IoClose size={16} />
                  </Button>
                ) : (
                  <Link
                    to={String(props.closeUrl)}
                    onClick={()=>{if(props?.pageTitle==="Add Expense")
                      dispatch(accountExpenseRequest({}));
                    }}
                    className="btn header-nav-btn"
                  >
                    <IoClose size={16} />
                  </Link>
                )}
              </OverlayTrigger>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default InnerHeader;
