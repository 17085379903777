import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//Development
// const BASE_URL = "http://127.0.0.1:8000/api/";
const BASE_URL = "https://app.rentcity.in/api/";
// const BASE_URL = "https://rentcityapi.winayak.com/api/";
interface PropData {
  method?: string;
  action: string;
  body?: any;
  isFormData?: boolean;
  isShowError?: boolean;
  id?: string;
  _file?: File;
  _body?: any;
  key?: string;
}

const WebService = {

  postAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      let bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "x-organization": localStorage.getItem("org_uuid"),
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
          }
        )
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  CommonApi: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }

      const handleResponse = (response:any) =>
        { 
          this.successHandler(response, resolve);
        };
      const handleError = (error:any) => {
        if (error.response && error.response.status === 401) {
          this.logout();
        }
        reject(props.isShowError ? this.errorHandler(error) : error);
      };
      const config = {
        headers: this.CommonHeader(),
      };

      const url = `${BASE_URL}${props.action}`;
      const data = props.isFormData ? bodyFormData : props.body;

      switch (props.method) {
        case "POST":
          axios.post(url, data, config).then(handleResponse).catch(handleError);
          break;
        case "PUT":
          axios.put(url, props.body, config).then(handleResponse).catch(handleError);
          break;
        case "GET":
          axios.get(url, config).then(handleResponse).catch(handleError);
          break;
        case "DELETE":
          axios.delete(url, config).then(handleResponse).catch(handleError);
          break;
        default:
          reject(new Error("Invalid method"));
      }
    });
  },
  CommonBeforeLoginApi: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
      };
      const handleResponse = (response:any) => resolve(response.data);
      const handleError = (error:any) => {
        if (error.response && error.response.status === 401) {
          this.logout();
        }
        reject(props.isShowError ? this.errorHandler(error) : error);
      };
      const config = {
        headers: headers,
      };

      const url = `${BASE_URL}${props.action}`;
      const data = props.isFormData ? bodyFormData : props.body;

      switch (props.method) {
        case "POST":
          axios.post(url, data, config).then(handleResponse).catch(handleError);
          break;
        case "PUT":
          axios.put(url, props.body, config).then(handleResponse).catch(handleError);
          break;
        case "GET":
          axios.get(url, config).then(handleResponse).catch(handleError);
          break;
        case "DELETE":
          axios.delete(url, config).then(handleResponse).catch(handleError);
          break;
        default:
          reject(new Error("Invalid method"));
      }
    });
  },


  uploadAPI: function (props: PropData) {
    let formData = new FormData();
    if (props._file && props.key) {
      formData.append(props.key, props._file);
    }
    for (let key in props._body) {
      formData.append(key, props._body[key]);
    }

    return new Promise((resolve, reject) => {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "x-organization": localStorage.getItem("org_uuid"),
      };
      axios
        .post(`${BASE_URL}${props.action}`, formData, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },
  putAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      let bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "x-organization": localStorage.getItem("org_uuid"),
      };
      axios
        .put(`${BASE_URL}${props.action}`, props.body, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  getAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "x-organization": localStorage.getItem("org_uuid"),
      };
      axios
        .get(`${BASE_URL}${props.action}`, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  deleteAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "x-organization": localStorage.getItem("org_uuid"),
      };
      axios
        .delete(`${BASE_URL}${props.action}`, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error && error.response && error.response.status === 401) {
            props.isShowError
              ? reject(this.errorHandler(error))
              : reject(error);
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  getWeatherAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      axios
        .get(`${BASE_URL}${props.action}`, {
          headers: headers,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error: any) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  errorHandler: function (error: any) {
    if (error?.response) {
      error = error.response;
    }
    let errorMessage;

    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      errorMessage = "Unauthorized";
      this.logout();
    } else if (error.status === 402) {
      window.location.href = "/subscription/plans";
    } else if (error.status === 412) {
      window.location.href = "/subscription/plans";
    } else if (error.status === 404) {
      errorMessage = "Server Not Responding";
      // this.logout();
    } else if (error.status === 500) {
      errorMessage =
        (error &&
          error.data &&
          error.data.ErrorDetails &&
          error.data.ErrorDetails.message) ||
        "An unkown exception has occur. Please Contact administrator";
    } else {
      errorMessage = error.data.message;
    }
    toast.error(errorMessage);
    return errorMessage;
  },

  successHandler: function (res: any, resolve: any) {
    if (res?.status === 208) {
      window.location.href = "/subscription/plans/request-sent";
    } else {
      resolve(res.data);
    }
  },

  addLoader(id?: any, status?: any) {
    if (id) {
      let button = document.getElementById(id) as HTMLButtonElement | null;
      if (button) {
        button.disabled = true;
        let loader = document.createElement("span");
        loader.innerHTML =
          '<span style="margin-right:5px" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span className="visually-hidden"></span>';
        button.prepend(loader);
      }
    }
  },

  removeLoader(id: any) {
    if (id) {
      let button = document.getElementById(id) as HTMLButtonElement | null;
      if (button != null) {
        button.disabled = false;
        button.removeChild(button.childNodes[0]);
      }
    }
  },

  logout() {
    localStorage.clear();
    window.location.href = "/login";
  },

  CommonHeader() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
      "x-organization": localStorage.getItem("org_uuid"),
      "Content-Type": "application/json",
    };
    return headers;
  },
};

export default WebService;
